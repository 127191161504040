import { useMutation, useQuery } from "react-query";
import { KEYS, MenuService } from "../context/MenuService";
import {useParams} from 'react-router-dom';
import {useProject} from './useProject';
import {useSnackbar} from 'notistack';

export const useUpdateProduct = () => {
  const { id: menuID } = useParams();
  const { projectID, token } = useProject();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation([KEYS.UPDATE_PRODUCT], async ({prodID,update}) => {
      const res = await MenuService.updateProduct({token, projectID, update, prodID});
      console.log(res);
    },
    {
      onError:(e)=>{
        enqueueSnackbar(e.message, {variant:'error'})
      }
    }
  );
};
