import { useMutation } from "react-query";
import { KEYS, MenuService } from "../context/MenuService";
import { useProject } from "./useProject";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

export const useSync = () => {
  const { projectID, token } = useProject();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  return useMutation(
    [KEYS.SYNC],
    () => MenuService.sync({ token, projectID }),
    {
      onSuccess: () => {
        enqueueSnackbar(t("sync_success"), { variant: "success" });
      },
      onError: () => {
        enqueueSnackbar(t("sync_error"), { variant: "error" });
      },
    },
  );
};
