import {useMutation} from 'react-query';
import {KEYS, MenuService} from '../context/MenuService';
import {useProject} from './useProject';

export const useRemoveQuantity = ()=>{
  const { projectID, token } = useProject();
  return useMutation([KEYS.DELETE_QUANTITY], async (id)=>{
    const res = await MenuService.removeQuantity({projectID, token, id})
  })
}
