import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { useProject } from "./useProject";
import { useSnackbar } from "notistack";
import { KEYS, MenuService } from "../context/MenuService";

export const useCreateProduct = () => {
  const { id: menuID } = useParams();
  const { projectID, token } = useProject();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation([KEYS.CREATE_PRODUCT], async (data) => {
    const result = await MenuService.createProduct({token, projectID, data})
    console.log(result);
    return result;
  },{
    onError:(e)=>{
      enqueueSnackbar(e.message,{variant:'error'})
    }
  });
};
