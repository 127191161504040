import {useMutation, useQueryClient} from 'react-query';
import {KEYS, MenuService} from '../context/MenuService';
import {useProject} from './useProject';

export const useUpdateGroup = () =>{
  const { projectID, token } = useProject();
  const queryClient = useQueryClient();
  return useMutation([KEYS.UPDATE_GROUP],async ({groupID,...update})=>{
    const result = await MenuService.updateGroup({
      projectID,
      token,
      groupID,
      update
    })
    queryClient.setQueriesData([KEYS.GROUP, result.id],result)
  })
}
