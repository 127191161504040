import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button, Grid } from "@mui/material";
import { useHistory } from "react-router-dom";
import { Paths } from "../../router/routes";
import { getPrograms } from "./programActions";
import { useSnackbar } from "notistack";
import { SocketContext } from "../../context/ConnectionProvider";
import { useTranslation } from "react-i18next";

const ProgramsPage = () => {
  const dispatch = useDispatch();
  const groups = useSelector(({ programs }) => programs.interface);
  const projectUID = useSelector(({ app }) => app.projectUID);
  const connection = useContext(SocketContext);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { t } = useTranslation();
  if (groups.length === 1) {
    history.push(`${Paths.Programs}/${groups[0]._id}`);
  }

  useEffect(() => {
    if (connection.socket)
      connection.socket.emit(
        "getPrograms",
        { projectUID },
        ({ status, message, data }) => {
          status === "success"
            ? dispatch(getPrograms(data))
            : enqueueSnackbar(message, { variant: status });
        },
      );
  }, [connection.socket]);

  const handleSelectGroup = (_id) => {
    history.push(`${Paths.Programs}/${_id}`);
  };
  const handleSelectSubGroup = (_id) => {
    history.push(`${Paths.Programs}/${_id}/sub`);
  };
  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      {groups.map((group) => (
        <Grid item key={group._id} xs={12} ms={12} md={4} lg={4} xl={3}>
          <Button
            onClick={() =>
              group.subGroups
                ? handleSelectSubGroup(group._id)
                : handleSelectGroup(group._id)
            }
            variant={"outlined"}
            fullWidth
            size="large"
            sx={{
              height: "7vh",
              minHeight: 60,
              borderColor: "#5D081C",
              color: "#5D081C",
              '&:hover':{
                borderColor: "#5D081C",
              }
            }}
          >
            {group.name}
          </Button>
        </Grid>
      ))}
      <Grid item xs={12} ms={12} md={4} lg={4} xl={3}>
        <Button
          onClick={() => history.push(Paths.MenuGroups)}
          variant={"outlined"}
          fullWidth
          size="large"
          sx={{
            height: "7vh",
            minHeight: 60,
            borderColor: "#5D081C",
            color: "#5D081C",
            '&:hover':{
              borderColor: "#5D081C",
            }
          }}
        >
          {t("menu")}
        </Button>
      </Grid>
    </Grid>
  );
};

export { ProgramsPage };
