
import {useContext, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getPrograms} from '../pages/Program/programActions';
import {SocketContext} from '../context/ConnectionProvider';

export const useSubscribeChanges = () =>{
  const dispatch = useDispatch();
  const connection = useContext(SocketContext);
  const programs = useSelector(({programs}) => programs.interface);
  const changeActiveProgram = (groupID, programID) => {
    const newPrograms = JSON.parse(JSON.stringify(programs)).map(group => {
      if (group._id === groupID) return {...group, activeProgram: programID}
      return group
    });
    dispatch(getPrograms(newPrograms))
  }

  useEffect(() => {
    connection.socket.on("activeProgramChange", changeActiveProgram);
    return () => {
      connection.socket.off("activeProgramChange", changeActiveProgram);
    }
  }, [changeActiveProgram])
}
