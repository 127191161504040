import {useMutation, useQueryClient} from 'react-query';
import {KEYS, MenuService} from '../context/MenuService';
import {useProject} from './useProject';
import {useSnackbar} from 'notistack';

export const useCreateQuantity = (groupID)=>{
  const { projectID, token } = useProject();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  return useMutation([KEYS.CREATE_QUANTITY],({prodID, newQuantity})=>MenuService.createQuantity({
    token,
    projectID,
    prodID,
    data:newQuantity
  }),{
    onSuccess:()=>{
      queryClient.invalidateQueries([KEYS.GROUP, groupID])
    }
  })
}
