import axios from "axios";

const httpClient = axios.create({
  baseURL: "/",
});

export const KEYS = {
  SYNC: "SYNC",
  MENUS: "MENUS:GET",
  MENU: "MENU:GET",
  GROUP: "GROUP:GET",
  CREATE_GROUP: "GROUP:CREATE",
  DELETE_GROUP: "GROUP:DELETE",
  UPDATE_GROUP: "GROUP:UPDATE",
  CREATE_PRODUCT: "PRODUCT:CREATE",
  DELETE_PRODUCT: "PRODUCT:DELETE",
  UPDATE_PRODUCT: "PRODUCT:UPDATE",
  CREATE_QUANTITY: "QUANTITY:CREATE",
  DELETE_QUANTITY: "QUANTITY:DELETE",
  UPDATE_QUANTITY: "QUANTITY:UPDATE",
};

export class MenuService {
  static async sync({ token, projectID }) {
    await httpClient.post("remote-screen/sync", undefined, {
      headers: {
        authorization: `Bearer ${token}`,
        projectuid: projectID,
      },
    });
  }

  static async getMenus({ token, projectID }) {
    const { data } = await httpClient.get("menu", {
      headers: {
        authorization: `Bearer ${token}`,
        projectuid: projectID,
      },
    });
    return data;
  }
  static async getGroup({ token, projectID, groupID }) {
    const { data } = await httpClient.get(`menu/group/${groupID}`, {
      headers: {
        authorization: `Bearer ${token}`,
        projectuid: projectID,
      },
    });
    return data;
  }
  static async updateGroup({ projectID, token, groupID, update }) {
    const { data } = await httpClient.patch(`menu/group/${groupID}`, update, {
      headers: {
        authorization: `Bearer ${token}`,
        projectuid: projectID,
      },
    });
    return data;
  }
  static async getMenu({ id, token, projectID }) {
    const { data } = await httpClient.get(`menu/${id}`, {
      headers: {
        authorization: `Bearer ${token}`,
        projectuid: projectID,
      },
    });
    return data;
  }
  static async createSubGroup({ token, projectID, menuID, data }) {
    const res = await httpClient.post(`menu/${menuID}/group`, data, {
      headers: {
        authorization: `Bearer ${token}`,
        projectuid: projectID,
      },
    });
    return res.data;
  }

  static async removeGroup({ token, projectID, id, menuID }) {
    const res = await httpClient.delete(`menu/${menuID}/group/${id}`, {
      headers: {
        authorization: `Bearer ${token}`,
        projectuid: projectID,
      },
    });
    return res.data;
  }

  static async createProduct({ token, projectID, data }) {
    const res = await httpClient.post(`products`, data, {
      headers: {
        authorization: `Bearer ${token}`,
        projectuid: projectID,
      },
    });
    return res.data;
  }

  static async updateProduct({ token, projectID, prodID, update }) {
    const res = await httpClient.patch(`products/${prodID}`, update, {
      headers: {
        authorization: `Bearer ${token}`,
        projectuid: projectID,
      },
    });
    return res.data;
  }

  static async removeProduct({ token, projectID, prodID }) {
    const res = await httpClient.delete(`products/${prodID}`, {
      headers: {
        authorization:`Bearer ${token}`,
        projectuid:projectID,
      },
    });
    return res;
  }

  static async removeQuantity({ token, projectID, id }) {
    const res = await httpClient.delete(`products/quantity/${id}`, {
      headers: {
        authorization:`Bearer ${token}`,
        projectuid:projectID,
      },
    });
    return res;
  }

  static async createQuantity({ token, projectID, prodID, data }) {
    const res = await httpClient.post(`products/${prodID}/quantity`, data, {
      headers: {
        authorization: `Bearer ${token}`,
        projectuid: projectID,
      },
    });
    return res.data;
  }

  static async updateQuantity({ token, projectID, id, update }) {
    const res = await httpClient.patch(`products/quantity/${id}`, update, {
      headers: {
        authorization: `Bearer ${token}`,
        projectuid: projectID,
      },
    });
    console.log(res);
    return res.data;
  }
}
