import React, { useCallback, useContext } from "react";
import { Button } from "@material-ui/core";

import { useDispatch, useSelector } from "react-redux";

import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { toggleSideMenu } from "../store/appActions";
import { SocketContext } from "../context/ConnectionProvider";

export const usePrograms = () => {
  const projectUID = useSelector(({ app }) => app.projectUID);
  const connection = useContext(SocketContext);
  const isAutoMode = useSelector(({ app }) => app.isAutoMode);
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const snackbarAction = useCallback(
    (key) => (
      <Button
        onClick={() => {
          dispatch(toggleSideMenu());
          closeSnackbar(key);
        }}
      >
        {t("message.on")}
      </Button>
    ),
    [],
  );

  const handleRunProgram = (groupID, programID) => {
    if (!isAutoMode) {
      enqueueSnackbar(t("message.manualMode"), {
        variant: "warning",
        action: snackbarAction,
      });
      return;
    }
    connection.socket.emit(
      "runProgram",
      { projectUID, groupID, programID },
      ({ status }) => {
        if (status === "success")
          enqueueSnackbar(t("message.successRun"), { variant: status });
        if (status === "error")
          enqueueSnackbar(t("message.failRun"), { variant: status });
        if (status === "warning")
          enqueueSnackbar(t("message.timeoutRun"), { variant: status });
      },
    );
  };
  return {
    handleRunProgram,
  };
};
