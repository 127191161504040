import { Button, Grid } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { IconButton, Typography } from "@mui/material";
import CastIcon from "@mui/icons-material/Cast";
import { useCreateGroup } from "../../hooks/useCreateGroup";
import { useSync } from "../../hooks/useSync";
import {useTranslation} from 'react-i18next';

export const MenuHeader = ({ title }) => {
  const { mutate, isLoading: creating } = useCreateGroup();
  const { mutate:sync, isLoading: isSyncPending } = useSync();
  const {t} = useTranslation();
  return (
    <Grid container alignItems={"center"} justifyContent={"space-between"}>
      <Grid item xs={6}>
        <Grid container>
          <Grid item xs={12}>
            <Typography sx={{ color: "#5D081C" }} variant={"h4"}>
              {t('menu')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ color: "#5D081C" }} variant={"subtitle1"}>
              {title}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6} textAlign={"right"}>
        <Button
          sx={{ color: "#5D081C" }}
          startIcon={<CastIcon />}
          disabled={isSyncPending}
          onClick={sync}
        >
          {t('sync')}
        </Button>
        <IconButton onClick={() => mutate({ name: "" })}>
          <AddCircleIcon sx={{ color: "#5D081C" }} fontSize={"medium"} />
        </IconButton>
      </Grid>
    </Grid>
  );
};
