import { Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useSelector } from "react-redux";

const useStyle = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    position: "relative",
    overscrollBehaviorY: "none",
    overflowX: "hidden",
    minHeight: "100vh",
    paddingTop: `calc(${theme.mixins.toolbar.minHeight * 1.2}px + env(safe-area-inset-top, 0))`,
    paddingBottom: `calc(${theme.mixins.toolbar.minHeight + 25}px + env(safe-area-inset-bottom, 0))`,
    "@media(min-width:768px)": {
      paddingTop: theme.mixins.toolbar.minHeight * 1.5,
      paddingBottom: theme.mixins.toolbar.minHeight * 1.5,
    },
  },
}));

const MainView = ({ children }) => {
  const classes = useStyle();
  const background = useSelector(({ theme }) => theme.background);
  return (
    <Container
      style={{ backgroundColor: background }}
      maxWidth={false}
      className={classes.root}
    >
      {children}
    </Container>
  );
};

export { MainView };
