import React from "react";
import {Box, Button, ButtonGroup, Grid, Hidden, IconButton, Tooltip, Typography} from "@material-ui/core";
import ViewConfigIcon from '@material-ui/icons/ViewComfy';
import ViewWeekIcon from '@material-ui/icons/ViewWeek';
import ViewDayIcon from '@material-ui/icons/ViewDay';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import {useTranslation} from "react-i18next";

const viewOptions = [
    {
        icon: ViewConfigIcon,
        label: 'Месяц',
        value: 'dayGridMonth'
    },
    {
        icon: ViewWeekIcon,
        label: 'Неделя',
        value: 'timeGridWeek'
    },
    {
        icon: ViewDayIcon,
        label: 'День',
        value: 'timeGridDay',
    }
];

export const CalendarToolbar = (props) => {
    const { date, onDateNext, onDatePrev, onDateToday, onViewChange, view } = props;
    const {t} = useTranslation();
    const handleViewChange = (newView) => {
        if (onViewChange) {
            onViewChange(newView);
        }
    };

    return (
        <Grid
            alignItems="center"
            container
            justifyContent="space-between"
            spacing={3}
        >
            <Grid item>
                {view === 'timeGridDay' ?<ButtonGroup size="small">
                    <Button style={{color:'#5d081c', borderColor:'#5d081c'}} onClick={()=>handleViewChange('dayGridMonth')}>
                        {t("calendar.interface.back")}
                    </Button>
                </ButtonGroup>:<ButtonGroup size="small">
                    <Button onClick={onDatePrev}>
                        <ArrowBackIosIcon style={{color:"#5d081c"}}/>
                    </Button>
                    <Button onClick={onDateToday} style={{color:"#5d081c"}}>
                        {t("calendar.interface.toDay")}
                    </Button>
                    <Button onClick={onDateNext}>
                        <ArrowForwardIosIcon style={{color:"#5d081c"}}/>
                    </Button>
                </ButtonGroup>}
            </Grid>
            <Hidden smDown>
                <Grid item>
                    <Box sx={{ color: 'text.primary' }}>
                        {viewOptions.map((viewOption) => {
                            const Icon = viewOption.icon;

                            return (
                                <Tooltip
                                    key={viewOption.value}
                                    title={viewOption.label}
                                >
                                    <IconButton
                                        style={{color:viewOption.value !== view ? '#5d081c':'#ab0000'}}
                                        onClick={() => handleViewChange(viewOption.value)}
                                    >
                                        <Icon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            );
                        })}
                    </Box>
                </Grid>
            </Hidden>
        </Grid>
    );
};
