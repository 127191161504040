import {useQuery, useQueryClient} from 'react-query';
import { KEYS, MenuService } from "../context/MenuService";
import { useProject } from "./useProject";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
const sortByOrder = (a, b) => a.order - b.order;
export const toTableRows = (products = []) => {
  return products.sort(sortByOrder).flatMap((p) => [
    {
      isQuantity: false,
      id: `${p.id}`,
      visible: p.visible,
      name: p.name,
      units: p.units,
      amount: null,
      price: null,
    },
    ...p.quantity.sort(sortByOrder).map((q) => ({
      isQuantity: true,
      id: `${p.id}/${q.id}`,
      visible: q.visible,
      name: null,
      units: null,
      amount: q.amount,
      price: q.price,
    })),
  ]);
};

export const useMenu = () => {
  const { id } = useParams();
  const { projectID, token } = useProject();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: [KEYS.MENU, projectID, id],
    queryFn: async () => {
      const data = await MenuService.getMenu({id, projectID, token});
      data.groups.forEach((group)=>{
        queryClient.setQueriesData([KEYS.GROUP, group.id],group)
      })
      
      return {
        ...data,
        groups:data.groups.map((group)=>({
          id:group.id,
          name:group.name
        }))
      }
    },
    enabled: !!projectID && !!token,
    onError: (err) => {
      enqueueSnackbar(err.massage, { variant: "error" });
    },
  });
};
