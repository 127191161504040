import {BottomNavigation, BottomNavigationAction, Box, Grid, makeStyles} from "@material-ui/core";
import MovieIcon from '@material-ui/icons/Movie';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import React, {memo, useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {Paths} from "../router/routes";
import {useSelector} from "react-redux";
import bottom_logo from "../media/newLogo.svg";
import {useTranslation} from "react-i18next";

const useStyle = makeStyles(() => ({
    root: {
        zIndex: 3,
        position: "fixed",
        bottom: 0,
        width: '100%',
        paddingBottom: "env(safe-area-inset-bottom, 0)",
    },
    imgContainer: {
        textAlign: "center",
        "& > img": {
            height: "40px",
            // paddingBottom: "8px"
        }
    }
}))

const BottomNav = memo(() => {
    const [value, setValue] = useState(0);
    const classes = useStyle();
    const menuBackground = useSelector(({theme}) => theme.menuBackground)
    const routes = [Paths.Programs, Paths.Calendar];
    const navigation = useHistory();
    const {t} = useTranslation();
    useEffect(()=>{
        navigation.push(routes[value])
    },[value])

    return (
        <Grid style={{
            backgroundColor: menuBackground
        }}
              className={classes.root}
              container>
            <Grid xs={12} item>
                <BottomNavigation
                    style={{
                        backgroundColor: menuBackground,
                        height: "56px"
                    }}
                    value={value}
                    onChange={(event, newValue) => {
                        setValue(newValue);
                    }}
                    showLabels

                >
                    <BottomNavigationAction label={t("main.programs")} icon={<MovieIcon/>}/>
                    <BottomNavigationAction label={t("main.calendar")} icon={<CalendarIcon/>}/>
                </BottomNavigation>
            </Grid>
            <Grid xs={12} className={classes.imgContainer} item><img src={bottom_logo} alt="logo"/></Grid>
        </Grid>

    )
}, [])

export {BottomNav}
