import React from 'react';
import {Divider, Drawer, List, ListItem, ListItemIcon, ListItemText, ListSubheader} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {toggleSideMenu} from "../store/appActions";
import {SetAutoModeSwitcher} from "./options/SetAutoModeSwitcher";
import LanguageButton from "./LangugeButton";
import TranslateIcon from '@material-ui/icons/Translate';
import {useTranslation} from "react-i18next";

const SideMenu = () => {
    const {t} = useTranslation();
    const openMenu = useSelector(({app}) => app.openMenu);
    const dispatch = useDispatch();
    const menuBackground = useSelector(({theme}) => theme.menuBackground);

    return (
        <Drawer anchor='right' open={openMenu} onClose={() => dispatch(toggleSideMenu())}>
            <List style={{paddingTop: "env(safe-area-inset-top, 0)", backgroundColor: menuBackground, height: "100%"}}
                  subheader={
                      <ListSubheader>{t("settings.header")}</ListSubheader>
                  }>
                <Divider/>
                <ListItem>
                    <ListItemIcon>
                        <SetAutoModeSwitcher/>
                    </ListItemIcon>
                    <ListItemText primary={t("settings.mode")}/>
                </ListItem>
                <LanguageButton/>
            </List>
        </Drawer>
    );
};

export default SideMenu;
