import { useMutation, useQueryClient } from "react-query";
import { KEYS, MenuService } from "../context/MenuService";
import { useProject } from "./useProject";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";

export const useRemoveGroup = (id) => {
  const { id: menuID } = useParams();
  const { projectID, token } = useProject();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  return useMutation(
    [KEYS.DELETE_GROUP, id],
    (id) => MenuService.removeGroup({ token, projectID, id, menuID }),
    {
      onSuccess: (data) => {
        queryClient.setQueriesData([KEYS.MENU,projectID,menuID],data)
      },
    },
  );
};
