import {useMutation} from 'react-query';
import {KEYS, MenuService} from '../context/MenuService';
import {useProject} from './useProject';

export const useRemoveProduct = ()=>{
  const { projectID, token } = useProject();
  return useMutation([KEYS.DELETE_PRODUCT],(id)=>MenuService.removeProduct({
    prodID:id,
    projectID,
    token
  }))
}
