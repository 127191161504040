import {useMutation} from 'react-query';
import {KEYS, MenuService} from '../context/MenuService';
import {useProject} from './useProject';

export const useUpdateQuantity = ()=>{
  const { projectID, token } = useProject();
  return useMutation([KEYS.UPDATE_QUANTITY],async ({id,update})=>{
    const result = await MenuService.updateQuantity({
      id,
      projectID,
      token,
      update
    })
    return result
  })
}
