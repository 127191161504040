import { useParams } from "react-router-dom";
import { Button, Typography } from "@material-ui/core";
import styled from "@emotion/styled";
import { usePrograms } from "../../../hooks/usePrograms";
const SubProgramBtn = styled(Button)`
  height: 150px;
  min-height: 60px;
  background-color: ${({ color_btn }) => color_btn}!important;
  border: 2px solid ${({ bg_color }) => bg_color};
  box-shadow: 0px 0px 15px 3px
    ${({ bg_color, active_btn }) => (active_btn ? bg_color : "transparent")};
  overflow: hidden;
  & video {
    position: absolute;
    z-index: ${({ active_btn }) => (active_btn ? 2 : 1)};
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: ${({ active_btn }) => (active_btn ? 1 : 0.3)};
  }
  & p {
    position: relative;
    z-index: ${({ active_btn }) => (active_btn ? 1 : 2)};
  }
`;

export const VideoBtn = ({
  _id,
  name,
  color,
  borderColor,
  isActive,
  video,
}) => {
  const { groupID } = useParams();
  const { handleRunProgram } = usePrograms();
  return (
    <SubProgramBtn
      variant={"outlined"}
      fullWidth
      size="large"
      active_btn={isActive ? true : undefined}
      bg_color={borderColor}
      color_btn={color}
      onClick={() => {
        handleRunProgram(groupID, _id);
      }}
    >
      {video && (
        <video
          src={`/static/video/${video}`}
          autoPlay
          loop
          muted
          playsInline
          preload={"auto"}
          crossOrigin={"anonymous"}
        />
      )}
      <Typography>{name}</Typography>
    </SubProgramBtn>
  );
};
