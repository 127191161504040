import {useMutation, useQueryClient} from 'react-query';
import { MenuService, KEYS } from "../context/MenuService";
import { useParams } from "react-router-dom";
import { useProject } from "./useProject";
import { useSnackbar } from "notistack";
import {useTranslation} from 'react-i18next';

export const useCreateGroup = () => {
  const { id: menuID } = useParams();
  const { projectID, token } = useProject();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const {t} = useTranslation();
  return useMutation([KEYS.CREATE_GROUP, projectID, menuID], (data) =>
    MenuService.createSubGroup({ token, projectID, menuID, data }),{
    onSuccess:()=>{
      queryClient.invalidateQueries([KEYS.MENU, projectID, menuID])
    },
    onError:(e)=>{
      enqueueSnackbar(e.message, {variant:'error'})
    }
    },
    
  );
};
