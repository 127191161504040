import { useDispatch, useSelector } from "react-redux";
import React, { useContext, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Paths } from "../../router/routes";

import { Box, Button, Grid, Typography } from "@material-ui/core";
import { getPrograms } from "./programActions";
import { SocketContext } from "../../context/ConnectionProvider";

const SubGroupsPage = () => {
  const { groupID } = useParams();
  const dispatch = useDispatch();
  const connection = useContext(SocketContext);
  const groups = useSelector(({ programs }) => programs.interface);
  const group = useSelector(({ programs }) =>
    programs.interface.find((group) => group._id === groupID),
  );

  const { activeSubName, color } = useSelector(({ programs }) => {
    const currentGroup = programs.interface.find(
      (group) => group._id === groupID,
    );
    let activeSubName = "";
    let color = "";
    currentGroup.subGroups &&
      currentGroup.subGroups.forEach((sub) => {
        const program = sub.programs.find(
          (program) => program._id === currentGroup.activeProgram,
        );
        if (program) {
          activeSubName = sub.name;
          color = program.borderColor;
        }
      });
    return {
      activeSubName,
      color,
    };
  });
  const history = useHistory();

  const handleSelectSubGroup = (groupID, name) => {
    history.push(`${Paths.Programs}/${groupID}/sub/${name}`);
  };

  const changeActiveProgram = (groupID, programID) => {
    const newPrograms = JSON.parse(JSON.stringify(groups)).map((group) => {
      if (group._id === groupID) return { ...group, activeProgram: programID };
      return group;
    });
    dispatch(getPrograms(newPrograms));
  };

  useEffect(() => {
    connection.socket.on("activeProgramChange", changeActiveProgram);
    return () => {
      connection.socket.off("activeProgramChange", changeActiveProgram);
    };
  }, [changeActiveProgram]);
  return (
    <>
      <Box mb={2} style={{}}>
        <Typography
          variant={"h3"}
          style={{ color: "#5d081c" }}
          align={"center"}
        >
          {group.name}
        </Typography>
      </Box>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        {group &&
          group.subGroups.map((subGroup) => (
            <Grid item key={subGroup.name} xs={12} ms={12} md={4} lg={4} xl={3}>
              <Button
                onClick={() => handleSelectSubGroup(groupID, subGroup.name)}
                variant={"outlined"}
                fullWidth
                size="large"
                style={
                  activeSubName === subGroup.name
                    ? {
                        height: "7vh",
                        minHeight: 60,
                        border: `2px solid ${color}`,
                        boxShadow: `0px 0px 15px 3px ${color}`,
                        color: "#5d081c",
                      }
                    : {
                        height: "7vh",
                        minHeight: 60,
                        color: "#5d081c",
                        borderColor: "#5d081c",
                      }
                }
              >
                {subGroup.name}
              </Button>
            </Grid>
          ))}
      </Grid>
    </>
  );
};

export { SubGroupsPage };
