import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { Paths } from "../../router/routes";
import { useSubscribeChanges } from "../../hooks/useSubscribeChanges";
import React from "react";
import { VideoBtn } from "../../coomponents/programs/Buttons/VideoButton";

const SubGroupProgramsPage = () => {
  useSubscribeChanges();
  const { groupID, subGroupName } = useParams();
  const history = useHistory();
  const group =
    useSelector(({ programs }) =>
      programs.interface.find((group) => group._id === groupID),
    ) || null;
  if (!group) return <>{history.replace(Paths.Programs)}</>;
  const subGroup =
    group && group.subGroups.find((sub) => sub.name === subGroupName);
  if (!subGroup) return <>{history.replace(Paths.Programs)}</>;
  const { name, programs } = subGroup;
  return (
    <>
      <Box mb={0.5} style={{ position: "relative" }}>
        <Typography
          variant={"h4"}
          style={{ color: "#5d081c" }}
          align={"center"}
        >
          {group.name}
        </Typography>
      </Box>
      <Box mb={2} style={{ position: "relative" }}>
        <Typography
          variant={"h6"}
          style={{ color: "#5d081c" }}
          align={"center"}
        >
          {name}
        </Typography>
      </Box>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        {programs.map((program) => (
          <Grid item key={program._id} xs={12} sm={6} md={6} lg={4} xl={4}>
            <VideoBtn
              {...program}
              isActive={group.activeProgram === program._id}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export { SubGroupProgramsPage };
