import { toTableRows, useMenu } from "../../hooks/useMenu";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  IconButton,
  Stack,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  GridRowModes,
  DataGrid,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridEditInputCell,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import { useCreateGroup } from "../../hooks/useCreateGroup";
import { useRemoveGroup } from "../../hooks/useRemoveGroup";
import { useGroup } from "../../hooks/useGroup";
import { useCreateProduct } from "../../hooks/useCreateProduct";
import { useUpdateProduct } from "../../hooks/useUpdateProduct";
import { useCreateQuantity } from "../../hooks/useCreateQuantity";
import { useUpdateQuantity } from "../../hooks/useUpdateQuantity";
import { useRemoveProduct } from "../../hooks/useRemoveProduct";
import { useRemoveQuantity } from "../../hooks/useRemoveQuantity";
import { MenuHeader } from "./MenuHeader";
import { useUpdateGroup } from "../../hooks/useUpdateGroup";
import {useTranslation} from 'react-i18next';

function EditToolbar(props) {
  const { setRows, setRowModesModel, groupID } = props;
  const { data: group } = useGroup(groupID);
  const { mutate: removeGroup } = useRemoveGroup(groupID);
  const { mutateAsync: createProduct } = useCreateProduct();
  const { mutateAsync: updateGroup } = useUpdateGroup();
  const handleClick = async () => {
    const { id, units, name, visible } = await createProduct({
      name: "",
      menuGroupId: groupID,
      units: "ml",
    });
    setRows((oldRows) => [
      ...oldRows,
      {
        isQuantity: false,
        id,
        visible,
        name,
        units,
        amount: null,
        price: null,
      },
    ]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: "name" },
    }));
  };
  const [name, setName] = useState(group?.name);
  useEffect(() => {
    setName(group?.name);
  }, [group?.name]);

  const saveName = async () => {
    if (name === group.name) return;
    await updateGroup({ groupID, name });
  };

  const changeVisibility = async (visible) => {
    await updateGroup({ groupID, visible });
  };
  return (
    <Stack
      direction={"row"}
      spacing={1}
      alignItems={"center"}
      justifyContent={"space-between"}
    >
      <Box display={"flex"} alignItems={"center"}>
        <Checkbox
          checked={!!group?.visible}
          onChange={(event, checked) => changeVisibility(checked)}
        />

        <TextField
          sx={{ ml: 1 }}
          variant={"standard"}
          value={name}
          onChange={(e) => setName(e.target.value)}
          onBlur={saveName}
        />
      </Box>
      <Box display={"flex"} alignItems={"center"}>
        <IconButton color={"inherit"} onClick={handleClick}>
          <AddIcon />
        </IconButton>
        <IconButton
          color={"inherit"}
          onClick={() => removeGroup(groupID)}
          ria-label="delete"
        >
          <DeleteIcon />
        </IconButton>
      </Box>

      {/*<Button color={"inherit"} startIcon={<AddIcon />} onClick={handleClick}>*/}
      {/*  Add new Product*/}
      {/*</Button>*/}
    </Stack>
  );
}
export const Menu = ({ groupID, groupName }) => {
  const {t} = useTranslation();
  const { data: group, isLoading } = useGroup(groupID);
  const { mutateAsync: updateRow } = useUpdateProduct();
  const { mutateAsync: createQuantity } = useCreateQuantity(groupID);
  const { mutateAsync: updateQuantity } = useUpdateQuantity();
  const { mutateAsync: removeProduct } = useRemoveProduct();
  const { mutateAsync: removeQuantity } = useRemoveQuantity();
  const [rows, setRows] = useState(toTableRows(group?.products) || []);
  const [rowModesModel, setRowModesModel] = React.useState({});
  useEffect(() => {
    setRows(toTableRows(group?.products) || []);
  }, [group?.products]);

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id, isQuantity) => async () => {
    if (isQuantity) {
      const [, quantityID] = id.split("/");
      await removeQuantity(quantityID);
      setRows((prevRows) => {
        const newRows = structuredClone(prevRows);
        return newRows.filter((row) => {
          if (row.id.toString().includes("/")) {
            const [, _quantityID] = row.id.split("/");
            if (_quantityID === quantityID) return false;
          }
          return true;
        });
      });
    }
    if (!isQuantity) {
      await removeProduct(id);
      setRows((prevRows) => {
        const newRows = structuredClone(prevRows);
        return newRows.filter((row) => {
          if (row.id.toString().includes("/")) {
            const [prodID] = row.id.split("/");
            if (prodID === id) return false;
          }
          return row.id !== id;
        });
      });
    }
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  const processRowUpdate = async (newRow) => {
    const { id, isQuantity, ...update } = newRow;
    if (isQuantity) {
      const [, quantityID] = id.split("/");
      const { name, units, ...rest } = update;
      await updateQuantity({ id: quantityID, update: rest });
    }
    if (!isQuantity) {
      const { amount, price, ...rest } = update;
      await updateRow({ prodID: id, update: rest });
    }
    return newRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };
  const handleNewQuantity = async (productID) => {
    const newQuantity = {
      visible: true,
      amount: 0,
      price: 0,
    };
    const { id, ...quantity } = await createQuantity({
      prodID: productID,
      newQuantity,
    });
    const combineID = `${productID}/${id}`;
    setRows((prevState) => {
      const newState = prevState.map((prop) => ({ ...prop }));
      const prodIndex = newState.findIndex(({ id }) => id === productID);
      newState.splice(prodIndex + 1, 0, {
        isQuantity: true,
        id: combineID,
        name: null,
        units: null,
        ...quantity,
      });
      return newState;
    });
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [combineID]: { mode: GridRowModes.Edit, fieldToFocus: "amount" },
    }));
  };
  const columns = [
    {
      disableColumnMenu: true,
      field: "visible",
      type: "boolean",
      editable: true,
      width: 50,
      headerName: "",
      resizable: false,
      sortable: false,
    },
    {
      flex: 1,
      disableColumnMenu: true,
      field: "name",
      headerName: t('name'),
      editable: true,
      renderEditCell: (props) => {
        if (props.row.isQuantity) return null;
        return <GridEditInputCell {...props} />;
      },
      minWidth: 200,
      sortable: false,
    },

    {
      flex: 1,
      disableColumnMenu: true,
      field: "amount",
      headerName: t('volume'),
      editable: true,
      renderEditCell: (props) => {
        if (!props.row.isQuantity) return null;
        return <GridEditInputCell {...props} />;
      },
      sortable: false,
    },
    {
      flex: 1,
      disableColumnMenu: true,
      field: "price",
      headerName: t("price"),
      editable: true,
      renderEditCell: (props) => {
        if (!props.row.isQuantity) return null;
        return <GridEditInputCell {...props} />;
      },
      sortable: false,
    },
    {
      field: "actions",
      type: "actions",
      width: 120,
      cellClassName: "actions",
      align: "right",
      getActions: ({ id, row }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        if (!row?.isQuantity) {
          if (isInEditMode) {
            return [
              <GridActionsCellItem
                icon={<SaveIcon />}
                label="Save"
                className="textPrimary"
                color="inherit"
                onClick={handleSaveClick(id)}
              />,
              <GridActionsCellItem
                icon={<CancelIcon />}
                label="Cancel"
                className="textPrimary"
                onClick={handleCancelClick(id)}
                color="inherit"
              />,
            ];
          }

          return [
            <GridActionsCellItem
              icon={<AddIcon />}
              label="Add"
              className="textPrimary"
              color="inherit"
              onClick={() => handleNewQuantity(id)}
            />,
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={handleEditClick(id)}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={handleDeleteClick(id, false)}
              color="inherit"
            />,
          ];
        }
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              className="textPrimary"
              color="inherit"
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id, true)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id, true)}
            color="inherit"
          />,
        ];
      },
      sortable: false,
    },
  ];
  return (
    <Grid
      style={{
        height: 400,
      }}
      item
      xs={12}
      xl={6}
    >
      <DataGrid
        style={{
          background: "white",
        }}
        density={"compact"}
        loading={isLoading}
        rows={rows}
        columns={columns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        checkboxSelection={false}
        slots={{
          toolbar: EditToolbar,
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel, groupID, groupName },
        }}
        hideFooter={true}
      />
    </Grid>
  );
};

export const Groups = () => {
  const { data, isLoading } = useMenu();
  return (
    <>
      <MenuHeader title={data?.name} />
      <Divider sx={{ background: "#5D081C", mb: 3 }} />
      <Grid mb={2} container spacing={2}>
        {data?.groups?.map((group) => (
          <Menu groupID={group.id} key={group.id} groupName={group.name} />
        ))}
      </Grid>
    </>
  );
};
