import {GET_PROGRAMS, RUN_PROGRAM} from "./programActions";
import {expandSubGroups} from '../../utils/expandGroups';
const initState = {
    title: "",
    interface: [],
}

export const programsReducer = (state = initState, action) => {
    const {type, payload} = action;
    switch (type) {

        case GET_PROGRAMS: {
            const withSubGroups = payload.map(expandSubGroups);
            return {
                ...state,
                interface: withSubGroups,
                originInterface:payload
            }
        }

        case RUN_PROGRAM: {
            return {
                ...state,
                interface: state.interface.map(group => {
                    if (group.name !== payload.groupName) return {...group}
                    return {
                        ...group,
                        activeProgram: payload.programId
                    }
                })
            }
        }
        default: {
            return {
                ...state
            }
        }
    }
}
