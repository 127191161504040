export const expandSubGroups = group => {
  const subGroupsObj = {};
  if(!group.programs) return group
  for(const program of group.programs){
    const [subName,programName,video] = program.name.split("_");
    if(programName === undefined) continue;
    if(programName.includes('mp4')){
      program.video = programName;
      program.name = subName;
      continue
    }
    if(programName.includes('jpg') || programName.includes('png')){
      program.image = programName;
      continue
    }
    
    if(programName === '') {
      subGroupsObj[subName] = {name: subName, programs: []};
      continue;
    }
    if(!(subName in subGroupsObj)) {
      subGroupsObj[subName] = {name: subName, programs: [{...program, name: programName, video}]};
      continue;
    }
    subGroupsObj[subName].programs.push({...program, name: programName, video})
  }
  const subGroupsList = Object.values(subGroupsObj);
  
  return subGroupsList.length
    ? {_id:group._id,name:group.name,activeProgram:group.activeProgram,subGroups:subGroupsList}
    : {...group}
}
