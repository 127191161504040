import {useQuery} from 'react-query';
import {KEYS, MenuService} from '../context/MenuService';
import {useProject} from './useProject';
import {useSnackbar} from 'notistack';

export const useGroup = (id) => {
  const { projectID, token } = useProject();
  const { enqueueSnackbar } = useSnackbar();
  return useQuery([KEYS.GROUP, id], () => MenuService.getGroup({
    projectID,
    token,
    groupID:id
  }),{
    enabled:!!id,
    onError:(e)=>{
      enqueueSnackbar(e.message, {variant:'error'})
    }
  })
};
