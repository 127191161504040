import {useQuery, useQueryClient} from 'react-query';
import { KEYS, MenuService } from "../context/MenuService";
import { useProject } from "./useProject";
import { useSnackbar } from "notistack";

export const useMenus = () => {
  const { projectID, token } = useProject();
  const { enqueueSnackbar } = useSnackbar();
  return useQuery({
    queryKey: [KEYS.MENUS, projectID],
    queryFn: async () => {
      const menus = await MenuService.getMenus({projectID, token});
      return menus;
    },
    enabled: !!projectID && !!token,
    onError: (err) => {
      console.log(err)
      enqueueSnackbar(err.massage, { variant: "error" });
    },
  });
};
