import {Box, Button, Grid, Typography} from '@mui/material';
import React from "react";
import { useTranslation } from "react-i18next";
import { useMenus } from "../../hooks/useMenus";
import {useHistory} from 'react-router-dom';
import {Paths} from '../../router/routes';

export const MenuGroups = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { data } = useMenus();
  return (
    <>
      <Box mb={2}>
        <Typography variant={"h4"} sx={{color:'#5D081C'}} align={"center"}>
          {t("menu")}
        </Typography>
      </Box>
      <Grid  container spacing={2} justifyContent="center" alignItems="center">
        {data?.map(({id, name})=>(
          <Grid  item key={id} xs={12} ms={12} md={3} lg={2} xl={2}>
            <Button
              onClick={() => history.push(`${Paths.MenuGroups}/${id}`)}
              variant={"outlined"}
              fullWidth
              size="large"
              style={{height:"7vh", minHeight:60, borderColor:'#5D081C', color:'#5D081C'}}
            >
              {name}
            </Button>
          </Grid>
        ))}
      </Grid>
    </>
  );
};
