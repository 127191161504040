import React from 'react';
import {AuthForm} from "../../coomponents/auth/AuthForm";
import {Box, Card, Grid, makeStyles} from "@material-ui/core";
import {useTranslation} from "react-i18next";
// paddingTop: `calc(${theme.mixins.toolbar.minHeight * 1.20}px + env(safe-area-inset-top, 0))`,
//     paddingBottom: `calc(${theme.mixins.toolbar.minHeight * 1.20}px + env(safe-area-inset-bottom, 0))`,
//     "@media(min-width:768px)": {
//     paddingTop: theme.mixins.toolbar.minHeight * 1.50,
//         paddingBottom: theme.mixins.toolbar.minHeight * 1.50
// }

const useStyle = makeStyles((theme) => ({
    container: {
        height: "100vh",
        backgroundColor: theme.palette.background.paper
    },
    card: {
        padding: '12px'
    },
    logo: {
        width: "100%"
    }
}))

const AuthPage = () => {
    const classes = useStyle();
    return (
        <Grid container justifyContent={"center"} alignItems={"center"} className={classes.container}>
            <Grid item xs={10} sm={8} md={6} lg={4} xl={3}>
                <Box mb={3}>
                    <img
                        className={classes.logo}
                        src={`${"/static/logo/"+ (window.localStorage.getItem("logo")||"default_logo.png")}`}
                        alt="Expolight Cloud Control System"/>
                </Box>
                <Card raised className={classes.card}>
                    <AuthForm/>
                </Card>
            </Grid>
        </Grid>
    );
};

export default AuthPage;
